const Footer = () => {
    return (
        <footer className="bg-[#0A0E1A] text-fontWhite text-center py-6">
            <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                <a href="#about" className="hover:text-[#2979FF] transition">
                    About Us
                </a>
                <a href="#privacy" className="hover:text-[#2979FF] transition">
                    Privacy Policy
                </a>
                <a href="#contact" className="hover:text-[#2979FF] transition">
                    Contact Us
                </a>
            </div>
            <p className="text-sm text-mutedGray mt-4">
                &copy; {new Date().getFullYear()} Virallizr. All rights reserved.
            </p>
        </footer>
    );
};

export default Footer;
import React, { useState } from "react";
import SearchBar from "../components/SearchBar";
import InfluencerCard from "../components/InfluencerCard";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function HomePage() {
    const [channels, setChannels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);

    const handleSearchResults = (results) => {
        setTimeout(() => {
            setChannels(results);
            setIsLoading(false);
        }, 100);
    };

    const renderSkeletons = () => (
        <div className="w-full max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-10 gap-x-[5%] px-4">
            {[...Array(8)].map((_, index) => (
                <div
                    key={index}
                    className="bg-gradient-to-r from-[#2A3742] to-[#1F2A35] rounded-lg h-64 animate-pulse"
                ></div>
            ))}
        </div>
    );

    const renderCards = () => (
        <div className="w-full max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-10 gap-x-[5%] px-4">
            {channels.map((channel, index) => (
                <InfluencerCard
                    key={index}
                    title={channel.title}
                    channelTitle={channel.channelTitle}
                    subscriberCount={channel.subscriberCount}
                    videoCount={channel.videoCount}
                    channelUrl={channel.channelUrl}
                />
            ))}
        </div>
    );

    const renderNoResults = () => (
        <div className="w-full flex justify-center items-center text-fontWhite text-lg mt-10">
            No results found.
        </div>
    );

    return (
        <div className="bg-darkBlueBackground min-h-screen flex flex-col justify-between">
            <Navbar />
            <div className="flex justify-center items-center mt-20 mb-6">
                <SearchBar
                    placeholder="Search for channels..."
                    onResults={(results) => {
                        setIsLoading(true);
                        setHasSearched(true);
                        handleSearchResults(results);
                    }}
                />
            </div>
            <div className="flex-grow">
                {isLoading
                    ? renderSkeletons()
                    : hasSearched
                        ? channels.length > 0
                            ? renderCards()
                            : renderNoResults()
                        : null}
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;
import React, { useState } from "react";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <nav className="w-full bg-navbarBackground flex items-center justify-between px-6 py-4 lg:px-8">
            <div
                className="text-fontWhite font-extrabold font-sans text-xl tracking-wide cursor-pointer"
                onClick={() => (window.location.href = "/")}
            >
                Virallizr
            </div>
            <div className="hidden lg:flex items-center space-x-6">
                <a
                    href="#login"
                    className="text-fontWhite hover:text-[#2979FF] hover:underline transition duration-200"
                >
                    Login
                </a>
                <a
                    href="#signup"
                    className="bg-[#2979FF] text-white rounded px-4 py-2 hover:bg-[#1F7BFF] transition duration-200"
                >
                    Sign Up
                </a>
            </div>
            <div className="lg:hidden relative">
                <button
                    className="text-fontWhite focus:outline-none"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    aria-label="Toggle menu"
                    aria-expanded={isMenuOpen}
                >
                    <div className="flex flex-col items-center space-y-1">
                        <span className="block w-6 h-0.5 bg-fontWhite"></span>
                        <span className="block w-6 h-0.5 bg-fontWhite"></span>
                        <span className="block w-6 h-0.5 bg-fontWhite"></span>
                    </div>
                </button>
                {isMenuOpen && (
                    <div className="absolute top-12 right-0 bg-navbarBackground w-48 rounded-lg shadow-lg text-fontWhite text-base z-10">
                        <a
                            href="#login"
                            className="block px-4 py-3 hover:text-[#2979FF] hover:bg-[#1F2A35] rounded-t-lg transition duration-200"
                        >
                            Login
                        </a>
                        <a
                            href="#signup"
                            className="block px-4 py-3 bg-[#2979FF] text-white hover:bg-[#1F7BFF] rounded-b-lg transition duration-200"
                        >
                            Sign Up
                        </a>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
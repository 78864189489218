const InfluencerCard = ({ title, channelTitle, subscriberCount, videoCount, channelUrl }) => {
    return (
        <div className="bg-navbarBackground rounded-lg shadow-md p-6 sm:p-4 h-full flex flex-col justify-between transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
            <h3 className="text-fontWhite font-bold text-lg sm:text-base">
                {title}
            </h3>
            <p className="text-mutedGray text-sm mt-2">Category: {channelTitle}</p>
            <p className="text-mutedGray text-sm mt-2">
                Subscribers: {Intl.NumberFormat().format(subscriberCount)}
            </p>
        </div>
    );
};

export default InfluencerCard;
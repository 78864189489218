const fetchChannels = async (keyword) => {
    const BASE_URL =
        process.env.REACT_APP_ENV === "prod"
            ? "https://v1ttjbkeda.execute-api.us-west-2.amazonaws.com/prod/channels"
            : "http://localhost:8000/channels";

    const url = `${BASE_URL}?search=${encodeURIComponent(keyword)}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch channels: ${response.status}`);
        }
        const data = await response.json();

        if (!data.channels || data.channels.length === 0) {
            return [];
        }

        return data.channels.map(({ channel }) => ({
            title: channel.title,
            channelTitle: channel.channelTitle,
            videoCount: channel.videoCount,
            subscriberCount: channel.subscriberCount,
            channelUrl: channel.channelUrl,
            keyword: channel.keyword,
        }));
    } catch (error) {
        console.error("Error fetching channels:", error.message);
        return [];
    }
};

export default fetchChannels;
import React from 'react';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';

function App() {
    return (
        <div className="min-h-screen bg-siteBackground text-fontWhite">
            <HomePage />
        </div>
    );
}

export default App;
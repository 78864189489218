import React from "react";

const CommonInput = ({
                         value,
                         onChange,
                         onEnter,
                         placeholder = "...",
                         className = "",
                         ...rest
                     }) => {
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (onEnter) {
                onEnter(value);
            }
        }
    };

    return (
        <input
            type="text"
            value={value}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            className={`w-full h-14 rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-hoverText ${className}`}
            {...rest}
        />
    );
};

export default CommonInput;
import React, { useState } from "react";
import CommonInput from "./CommonInput";
import fetchChannels from "../api/fetchChannels";

const SearchBar = ({ placeholder, onResults }) => {
    const [inputValue, setInputValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSearch = async (value) => {
        if (value.trim()) {
            setLoading(true);
            setError(null);

            try {
                const channels = await fetchChannels(value.trim());
                onResults(channels);
            } catch (err) {
                setError("Failed to fetch results. Please try again.");
                console.error("Search Error:", err.message);
            } finally {
                setLoading(false);
                setInputValue("");
            }
        }
    };

    return (
        <div className="w-full flex justify-center items-center mt-12 px-4 sm:px-6">
            <div className="w-full lg:w-3/5 md:w-4/5 sm:w-11/12 flex flex-col sm:flex-row items-center gap-4">
                <CommonInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onEnter={handleSearch}
                    placeholder={placeholder}
                    className="h-14 sm:h-16 w-full text-darkGray rounded-lg px-4 focus:outline-none focus:ring-2 focus:ring-hoverText"
                />
                <button
                    onClick={() => handleSearch(inputValue)}
                    className="h-14 sm:h-16 px-6 bg-[#3A4A55] text-fontWhite rounded-lg shadow-md hover:bg-[#2A3742] hover:shadow-lg focus:outline-none transition duration-200"
                    disabled={loading}
                >
                    {loading ? "Loading..." : "Search"}
                </button>
            </div>
            {error && <p className="text-red-500 mt-4 sm:mt-2 text-center">{error}</p>}
        </div>
    );
};

export default SearchBar;